import { render, staticRenderFns } from "./InstallationBanner.vue?vue&type=template&id=3659a547&scoped=true"
import script from "./InstallationBanner.vue?vue&type=script&lang=js"
export * from "./InstallationBanner.vue?vue&type=script&lang=js"
import style0 from "./InstallationBanner.vue?vue&type=style&index=0&id=3659a547&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3659a547",
  null
  
)

export default component.exports
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QImg,QBtn});
