<template>
  <div
    :class="[
      'installApp installWebContent',
      show ? 'show' : '',
      isDialog ? 'dialog' : '',
    ]"
  >
    <div class="container">
      <div
        :class="[
          'row q-col-gutter-md items-center',
          isDialog ? '' : 'text-center',
        ]"
      >
        <div :class="['self-center', isDialog ? 'col-3' : 'col-12']">
          <!-- <Logo /> -->
          <q-img
            :src="logoURL"
            spinner-color="primary"
            spinner-size="20px"
            contain
            style="width: 60px; height: 60px"
          />
        </div>
        <div :class="['', isDialog ? 'col-9' : 'col-12']">
          <div
            class="text-subtitle1 text-weight-bold word-break-word line-height-normal q-pb-sm"
          >
            {{ pwaAppName }}
          </div>
          <div class="text-caption q-pb-sm word-break-word">
            Install our web app for free and enjoy shopping!
          </div>
          <div class="row q-col-gutter-md">
            <div class="col" v-if="!hideClose">
              <q-btn
                unelevated
                no-caps
                outline
                color="tertiary"
                label="Later"
                class="full-width"
                @click="$root.$emit('handle-pwa-action', 0)"
              />
            </div>
            <div class="col">
              <q-btn
                unelevated
                no-caps
                color="secondaryOnBody"
                id="install-button"
                label="Install"
                class="full-width"
                @click="$root.$emit('handle-pwa-action', 1)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstallationBanner',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoURL() {
      return this.businessConfigurations?.pwaImageUrl || this.theme?.logoURL
    },
    pwaAppName() {
      return this.businessConfigurations?.pwaAppName
        ? `Install ${this.businessConfigurations.pwaAppName}`
        : `Install ${this.businessConfigurations.businessName}`
    },
  },
}
</script>

<style scoped>
.installWebContent:not(.dialog) {
  padding: 16px 8px 16px;
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.installWebContent {
  visibility: hidden;
}

.installWebContent.dialog {
  background: #fff;
  max-width: 375px;
  position: fixed;
  z-index: 999999;
  left: 16px;
  right: 16px;
  bottom: 16px;
  padding: 16px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);

  transform: translateY(120%);
  transition: all 0.3s ease-in-out;
}
.installWebContent.show {
  visibility: visible;
  transform: translateY(0);
}
</style>
