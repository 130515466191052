var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'installApp installWebContent',
    _vm.show ? 'show' : '',
    _vm.isDialog ? 'dialog' : '',
  ]},[_c('div',{staticClass:"container"},[_c('div',{class:[
        'row q-col-gutter-md items-center',
        _vm.isDialog ? '' : 'text-center',
      ]},[_c('div',{class:['self-center', _vm.isDialog ? 'col-3' : 'col-12']},[_c('q-img',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":_vm.logoURL,"spinner-color":"primary","spinner-size":"20px","contain":""}})],1),_c('div',{class:['', _vm.isDialog ? 'col-9' : 'col-12']},[_c('div',{staticClass:"text-subtitle1 text-weight-bold word-break-word line-height-normal q-pb-sm"},[_vm._v("\n          "+_vm._s(_vm.pwaAppName)+"\n        ")]),_c('div',{staticClass:"text-caption q-pb-sm word-break-word"},[_vm._v("\n          Install our web app for free and enjoy shopping!\n        ")]),_c('div',{staticClass:"row q-col-gutter-md"},[(!_vm.hideClose)?_c('div',{staticClass:"col"},[_c('q-btn',{staticClass:"full-width",attrs:{"unelevated":"","no-caps":"","outline":"","color":"tertiary","label":"Later"},on:{"click":function($event){return _vm.$root.$emit('handle-pwa-action', 0)}}})],1):_vm._e(),_c('div',{staticClass:"col"},[_c('q-btn',{staticClass:"full-width",attrs:{"unelevated":"","no-caps":"","color":"secondaryOnBody","id":"install-button","label":"Install"},on:{"click":function($event){return _vm.$root.$emit('handle-pwa-action', 1)}}})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }